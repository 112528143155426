import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  ANY_USER,
  ANY_ACTIVE_NON_RIDE_USER,
  MENU_ITEMS,
  MENU_ICON,
  OPEN_MODAL_ID,
  USER_TYPE,
} from 'config'
import { isEmpty } from 'lodash'
import {
  IonAccordion,
  IonAccordionGroup,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
} from '@ionic/react'
import { NavMenuItem } from 'components'

const propTypes = {
  currentUser: Types.user.isRequired,
  openUploadModal: PropTypes.func.isRequired,
  activeHubMessagesCount: PropTypes.number.isRequired,
}

const defaultProps = {}

function NavMenuMainLinks({
  currentUser,
  openUploadModal,
  activeHubMessagesCount,
}) {
  const { clientCustomerType, constituentId, isActive } = currentUser

  const getMenuItemHref = (item) => {
    if (!item.injectUserData) return item.href

    return `${item.href}?constituentId=${constituentId}`
  }

  const handleMenuItemClick = (id) => {
    if (id !== OPEN_MODAL_ID) return null

    openUploadModal()
  }

  const isMenuItemAuthorizedForUser = useCallback(
    (menuItem) => {
      const { allowedUserTypes, requiresActiveUser } = menuItem
      if (requiresActiveUser && !isActive) return false
      if (allowedUserTypes === ANY_USER) return true
      if (allowedUserTypes === ANY_ACTIVE_NON_RIDE_USER)
        return clientCustomerType !== USER_TYPE.RIDE

      return allowedUserTypes.includes(clientCustomerType)
    },
    [clientCustomerType, isActive]
  )

  const getAuthorizedAccordionMenuItems = useCallback(
    (menuItems) => {
      return menuItems.reduce((result, menuItem) => {
        const authorizedSubmenuItems = menuItem.submenuItems.filter(
          (submenuItem) => isMenuItemAuthorizedForUser(submenuItem)
        )
        if (!isEmpty(authorizedSubmenuItems)) {
          result.push({
            ...menuItem,
            submenuItems: authorizedSubmenuItems,
          })
        }
        return result
      }, [])
    },
    [isMenuItemAuthorizedForUser]
  )

  const authorizedMenuItems = useMemo(() => {
    return MENU_ITEMS.reduce((result, item) => {
      if (item.accordionGroup) {
        const authorizedAccordionMenuItems = getAuthorizedAccordionMenuItems(
          item.menuItems
        )
        if (!isEmpty(authorizedAccordionMenuItems)) {
          result.push({
            ...item,
            menuItems: authorizedAccordionMenuItems,
          })
        }
        return result
      }

      if (isMenuItemAuthorizedForUser(item)) {
        result.push(item)
      }
      return result
    }, [])
  }, [getAuthorizedAccordionMenuItems, isMenuItemAuthorizedForUser])

  if (isEmpty(authorizedMenuItems)) return null
  return (
    <div className="main-links">
      <div className="ion-content-scroll-host">
        <IonList className="menu-navigation">
          <div className="main-menu-nav-group">
            {authorizedMenuItems.map((item, index) => {
              if (item.accordionGroup) {
                return (
                  <IonAccordionGroup key={index}>
                    {item.menuItems.map((menuItem) => (
                      <IonAccordion key={menuItem.name} value={menuItem.name}>
                        <IonItem
                          className="accordion-label-button"
                          slot="header"
                          lines="none"
                        >
                          <IonIcon
                            slot="start"
                            icon={MENU_ICON[menuItem.icon]}
                          />
                          <IonLabel>{menuItem.name}</IonLabel>
                        </IonItem>
                        <IonList className="accordion-contents" slot="content">
                          {menuItem.submenuItems.map((submenuItem) => (
                            <NavMenuItem
                              key={submenuItem.name}
                              menuItem={submenuItem}
                              handleClick={() =>
                                handleMenuItemClick(submenuItem.id)
                              }
                              href={getMenuItemHref(submenuItem)}
                            />
                          ))}
                        </IonList>
                      </IonAccordion>
                    ))}
                  </IonAccordionGroup>
                )
              }

              return (
                <NavMenuItem
                  key={item.name}
                  menuItem={item}
                  handleClick={() => handleMenuItemClick(item.id)}
                  href={getMenuItemHref(item)}
                  activeHubMessagesCount={activeHubMessagesCount}
                />
              )
            })}
          </div>
        </IonList>
      </div>
    </div>
  )
}

NavMenuMainLinks.propTypes = exact(propTypes)
NavMenuMainLinks.defaultProps = defaultProps

export default React.memo(NavMenuMainLinks)
