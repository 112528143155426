import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { LimitedUserHome, StandardUserHome } from '../components'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors as hubMessagesSelectors } from '../../hub-messages/reducer'

const propTypes = {
  fetchLatestDocs: PropTypes.func.isRequired,
  fetchConsultantContact: PropTypes.func.isRequired,
  fetchHomeProfitAndLoss: PropTypes.func.isRequired,
  fetchHomePerDiem: PropTypes.func.isRequired,
  fetchHomeMileageSummary: PropTypes.func.isRequired,
  currentUser: Types.user.isRequired,
  profitAndLoss: Types.profitAndLoss,
  perDiem: Types.perDiem,
  latestDocs: PropTypes.arrayOf(Types.doc),
  savings: PropTypes.number,
  hasActiveHubMessages: PropTypes.bool.isRequired,
}

const defaultProps = {
  profitAndLoss: null,
  perDiem: null,
  latestDocs: null,
  savings: null,
}

function Home({
  fetchLatestDocs,
  fetchConsultantContact,
  fetchHomeProfitAndLoss,
  fetchHomePerDiem,
  fetchHomeMileageSummary,
  currentUser,
  profitAndLoss,
  perDiem,
  latestDocs,
  savings,
  hasActiveHubMessages,
}) {
  if (currentUser.isLimited) {
    return (
      <LimitedUserHome
        {...{
          fetchLatestDocs,
          fetchConsultantContact,
          latestDocs,
          hasActiveHubMessages,
          currentUser,
        }}
      />
    )
  }

  return (
    <StandardUserHome
      {...{
        fetchLatestDocs,
        fetchConsultantContact,
        fetchHomeProfitAndLoss,
        fetchHomePerDiem,
        fetchHomeMileageSummary,
        currentUser,
        profitAndLoss,
        perDiem,
        latestDocs,
        savings,
        hasActiveHubMessages,
      }}
    />
  )
}

Home.propTypes = propTypes
Home.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    profitAndLoss: selectors.profitAndLoss(state),
    perDiem: selectors.perDiem(state),
    latestDocs: selectors.latestDocs(state),
    currentUser: globalSelectors.currentUser(state),
    savings: selectors.savings(state),
    hasActiveHubMessages: hubMessagesSelectors.hasActiveHubMessages(state),
  }
}

const mapDispatchToProps = {
  fetchLatestDocs: apiActions.fetchLatestDocs,
  fetchConsultantContact: apiActions.fetchConsultantContact,
  fetchHomeProfitAndLoss: apiActions.fetchHomeProfitAndLoss,
  fetchHomePerDiem: apiActions.fetchHomePerDiem,
  fetchHomeMileageSummary: apiActions.fetchHomeMileageSummary,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Home)
