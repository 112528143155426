import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  IonCard,
  IonRouterLink,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonCardContent,
} from '@ionic/react'
import { APP_PATH } from 'config'
import { carOutline } from 'ionicons/icons'

const propTypes = {
  userIsActive: PropTypes.bool.isRequired,
}
const defaultProps = {}

const NewTripTrackingCard = ({ userIsActive }) => (
  <IonCard className="ion-padding-medium centered">
    <IonCardContent>
      <div className="icon-circle-bg dark">
        <IonIcon
          icon={carOutline}
          aria-hidden="true"
          size="large"
          color="light"
        />
      </div>
      <IonCardHeader>
        <IonCardTitle>New Trip Tracking</IonCardTitle>
      </IonCardHeader>
      <p>
        GPS Tracking may drain your battery quickly. Check that your device is
        connected to a power source or that your battery is fully charged.
      </p>
      <IonRouterLink routerLink={APP_PATH.MILEAGE_TRACKER.ALL_TRIPS}>
        See All Trips
      </IonRouterLink>
      {userIsActive && (
        <IonRouterLink
          routerLink={APP_PATH.FINANCIAL_PERFORMANCE.PROFIT_AND_LOSS}
        >
          Profit and Loss
        </IonRouterLink>
      )}
    </IonCardContent>
  </IonCard>
)

NewTripTrackingCard.propTypes = exact(propTypes)
NewTripTrackingCard.defaultProps = defaultProps

export default NewTripTrackingCard
