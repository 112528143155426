import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import {
  MileageTrackerLink,
  LoadingSpinner,
  PaginatedItems,
  DownloadCsvButton,
} from 'components'
import {
  IonIcon,
  IonRouterLink,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonNote,
} from '@ionic/react'
import { carOutline, createOutline } from 'ionicons/icons'
import { APP_PATH, CELL_FORMATTING } from 'config'
import { intervalToDuration } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { MileageSummaryCard } from '../components'
import { getLocalStorageTrip } from '../../../services/mileageTrackerStorage'
import classnames from 'classnames'

const propTypes = {
  fetchTrips: PropTypes.func.isRequired,
  trips: PropTypes.arrayOf(Types.trip),
  mileageSummary: Types.mileageSummary,
}

const defaultProps = {
  trips: null,
  mileageSummary: null,
}

// Since we are only displaying hours and minutes, adjust if needed based on days and seconds values
function adjustDurationInHoursAndMinutes(duration) {
  const { days, hours, minutes, seconds } = duration

  let adjustedHours = hours ?? 0
  let adjustedMinutes = minutes ?? 0

  if (seconds && seconds >= 30) adjustedMinutes += 1
  if (days) adjustedHours += days * 24
  if (adjustedMinutes === 60) {
    adjustedHours += 1
    adjustedMinutes = 0
  }

  return { hours: adjustedHours, minutes: adjustedMinutes }
}

function AllTrips({ fetchTrips, trips, mileageSummary }) {
  const [error, setError] = useState(null)

  useEffect(() => {
    const requestTrips = async () => {
      try {
        await fetchTrips()
      } catch (errorMessage) {
        setError(`There was an issue fetching your trips: ${errorMessage}`)
      }
    }

    requestTrips()
  }, [fetchTrips])

  if (error) return <div className="centered-content">{error}</div>

  if (!trips) return <LoadingSpinner />

  if (trips.length === 0) {
    return (
      <div className="centered-content full-page header-offset">
        <div className="icon-circle-bg dark">
          <IonIcon
            icon={carOutline}
            aria-hidden="true"
            size="large"
            color="light"
          />
        </div>
        <h2>No Trackings</h2>
        <p>You will see here all your trips and related information here.</p>
        <MileageTrackerLink routerDirection="back" />
        <IonRouterLink
          className="minimal"
          routerLink={APP_PATH.MILEAGE_TRACKER.ADD_TRIP}
        >
          Add Manually
        </IonRouterLink>
      </div>
    )
  }

  return (
    <div>
      <MileageSummaryCard
        miles={mileageSummary.totalMiles}
        savings={mileageSummary.totalSavings}
      />
      <div className="all-trips-wrapper">
        <header>
          <h2>Trips</h2>
          <DownloadCsvButton url="/v1/trips.csv" fileName="trips.csv" />
        </header>
        <PaginatedItems
          items={trips}
          itemsPerPage={5}
          itemComponent={({ item: trip }) => {
            const duration = intervalToDuration({
              start: trip.startTime,
              end: trip.endTime,
            })
            const { hours, minutes } = adjustDurationInHoursAndMinutes(duration)
            const isLiveTrip = trip.id === getLocalStorageTrip()?.id

            return (
              <IonCard
                className={classnames('ion-padding-xsmall', {
                  'is-live': isLiveTrip,
                })}
                routerLink={
                  isLiveTrip
                    ? `${APP_PATH.MILEAGE_TRACKER.TRIP_TRACKER}`
                    : `${APP_PATH.MILEAGE_TRACKER.EDIT_TRIP}/${trip.id}`
                }
              >
                <div className="trip-card-wrapper">
                  <IonCardHeader>
                    <IonCardTitle>
                      {formatInTimeZone(
                        new Date(trip.startTime),
                        trip.startTimeZone,
                        'MM/dd/yyyy - hh:mmaaa zzz'
                      )}
                    </IonCardTitle>
                  </IonCardHeader>
                  {!isLiveTrip && (
                    <IonIcon
                      icon={createOutline}
                      aria-label={`Edit trip for ${formatInTimeZone(
                        new Date(trip.startTime),
                        trip.startTimeZone,
                        "MMMM do yyyy 'at' h:mma zzz"
                      )}`}
                    />
                  )}
                  <div className="trip-summary">
                    <dl>
                      <div className="column">
                        <dt>Miles</dt>
                        <dd>{trip.miles.toLocaleString()}</dd>
                      </div>
                      <div className="column">
                        <dt>Duration</dt>
                        <dd>
                          {hours}:{minutes.toString().padStart(2, '0')}
                        </dd>
                      </div>
                      <div className="column">
                        <dt>Savings</dt>
                        <dd>
                          {trip.savings.toLocaleString(
                            'en-US',
                            CELL_FORMATTING.CURRENCY
                          )}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
                {trip.note && (
                  <div className="trip-note-wrapper">
                    <IonLabel>Note</IonLabel>
                    <IonNote>{trip.note}</IonNote>
                  </div>
                )}
              </IonCard>
            )
          }}
        />
      </div>
    </div>
  )
}

AllTrips.propTypes = exact(propTypes)
AllTrips.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    trips: selectors.trips(state),
    mileageSummary: selectors.mileageSummary(state),
  }
}

const mapDispatchToProps = {
  fetchTrips: apiActions.fetchTrips,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AllTrips)
