import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'
import * as HomeViews from './home/views'
import * as PerDiemViews from './per-diem/views'
import * as DocumentsViews from './documents/views'
import * as FinancialPerformanceViews from './financial-performance/views'
import { FinancialPerformanceYearsProvider } from './financial-performance/providers'
// import { ComingSoon } from './coming-soon'
import * as TaxViews from './tax/views'
import * as HubMessagesViews from './hub-messages/views'
import * as ResourcesViews from './resources/views'
import * as SignInViews from './sign-in/views'
import * as ErrorViews from './errors'
import NavMenuLayout from './NavMenuLayout'
import Layout from './Layout'
import UploadFlowLayout from './UploadFlowLayout'
import UploadFlowRedirect from './UploadFlowRedirect'
import AuthenticatedLayout from './AuthenticatedLayout'
import UnauthenticatedLayout from './UnauthenticatedLayout'
import { PermissionsSplash } from 'components'
import { startCase, toNumber } from 'lodash'
import {
  APP_PATH,
  PERMISSIONS_SPLASH,
  OPEN_MILEAGE_TRACKER_INFO_MODAL,
  OPEN_PER_DIEM_INFO_MODAL,
} from 'config'
import { useDocumentShare, useAppUrlListener } from 'hooks'
import * as MileageTrackerViews from './mileage-tracker/views'
import * as ConnectBankViews from './connect-bank/views'
import { getLocalStorageTrip } from '../services/mileageTrackerStorage'

function Routes() {
  useAppUrlListener()
  useDocumentShare()
  return (
    <IonRouterOutlet>
      <Route exact path={APP_PATH.SIGN_IN}>
        <UnauthenticatedLayout hideBackButton>
          <SignInViews.SignIn />
        </UnauthenticatedLayout>
      </Route>
      <Route exact path={APP_PATH.FORGOT_PASSWORD}>
        <UnauthenticatedLayout>
          <SignInViews.ForgotPassword />
        </UnauthenticatedLayout>
      </Route>
      <Route exact path={APP_PATH.RESET_PASSWORD}>
        <UnauthenticatedLayout>
          <SignInViews.ResetPassword />
        </UnauthenticatedLayout>
      </Route>
      <Route exact path={`${APP_PATH.IMPERSONATE}/:constituentId`}>
        <UnauthenticatedLayout>
          <SignInViews.Impersonate />
        </UnauthenticatedLayout>
      </Route>
      <Route path={APP_PATH.UPLOAD.ROOT}>
        <AuthenticatedLayout>
          <IonRouterOutlet>
            <Route
              exact
              path={`${APP_PATH.UPLOAD.CAMERA}/:pageType/:hubMessageId?`}
            >
              <UploadFlowRedirect>
                <UploadFlowLayout title="Scan">
                  <DocumentsViews.CameraFlow />
                </UploadFlowLayout>
              </UploadFlowRedirect>
            </Route>
            <Route
              exact
              path={`${APP_PATH.UPLOAD.FILE}/:pageType/:hubMessageId?`}
            >
              <UploadFlowRedirect>
                <UploadFlowLayout title="Import">
                  <DocumentsViews.UploadFilesFlow />
                </UploadFlowLayout>
              </UploadFlowRedirect>
            </Route>
            <Route
              exact
              path={`${APP_PATH.UPLOAD.DOCUMENT_MANAGER}/:hubMessageId?`}
            >
              <UploadFlowRedirect>
                <UploadFlowLayout title="Document Manager">
                  <DocumentsViews.DocumentManager />
                </UploadFlowLayout>
              </UploadFlowRedirect>
            </Route>
            <Route exact path={`${APP_PATH.UPLOAD.SUCCESS}/:hubMessageId?`}>
              <UploadFlowRedirect>
                <UploadFlowLayout htmlTitle="Success">
                  <DocumentsViews.SuccessSplash />
                </UploadFlowLayout>
              </UploadFlowRedirect>
            </Route>
          </IonRouterOutlet>
        </AuthenticatedLayout>
      </Route>
      <AuthenticatedLayout>
        <NavMenuLayout>
          <IonRouterOutlet id="main-content">
            {/* Home */}
            <Route exact path={APP_PATH.HOME}>
              <Layout showUploadCTA hideBackButton isWelcomeHeader>
                <HomeViews.Home />
              </Layout>
            </Route>
            {/* Per Diem */}
            <Route exact path={APP_PATH.PER_DIEM}>
              <Layout
                headerTitle="Per Diem"
                infoModalTriggerId={OPEN_PER_DIEM_INFO_MODAL}
              >
                <PerDiemViews.PerDiem />
              </Layout>
            </Route>
            {/* Documents */}
            <Route exact path={APP_PATH.DOCUMENTS.ALL}>
              <Layout headerTitle="Documents">
                <DocumentsViews.AllDocuments />
              </Layout>
            </Route>
            <Route
              exact
              path={`${APP_PATH.DOCUMENTS.VIEW}/:id`}
              render={({ match }) => (
                <Layout
                  headerTitle="View Document"
                  htmlTitle={`${match.params.id} - Document`}
                >
                  <DocumentsViews.DocumentShow />
                </Layout>
              )}
            ></Route>
            <Route exact path={APP_PATH.DOCUMENTS.PERMISSIONS_NEEDED}>
              <UploadFlowLayout htmlTitle="Permissions Needed">
                <PermissionsSplash type={PERMISSIONS_SPLASH.camera.type} />
              </UploadFlowLayout>
            </Route>
            {/* This can't be wrapped in the standard document flow because the UploadFlowRedirect would boot the user out */}
            <Route exact path={`/documents/share`}>
              <UploadFlowLayout htmlTitle="Share Document">
                <DocumentsViews.ShareManager />
              </UploadFlowLayout>
            </Route>
            <Route exact path={APP_PATH.DOCUMENTS.ROOT}>
              <Redirect to={APP_PATH.DOCUMENTS.ALL} />
            </Route>
            {/* Financial Performance */}
            <Route exact path={APP_PATH.FINANCIAL_PERFORMANCE.BALANCE_SHEET}>
              <Layout headerTitle="Balance Sheet">
                <FinancialPerformanceYearsProvider>
                  <FinancialPerformanceViews.BalanceSheet />
                </FinancialPerformanceYearsProvider>
              </Layout>
            </Route>
            <Route
              exact
              path={`${APP_PATH.FINANCIAL_PERFORMANCE.BS_TRANSACTIONS_BATCH}/:batchId`}
              render={({ match }) => (
                <Layout
                  htmlTitle={`${match.params.batchId} - Document Transactions`}
                  headerTitle="Document Transactions"
                  backHref={APP_PATH.FINANCIAL_PERFORMANCE.BALANCE_SHEET}
                >
                  <FinancialPerformanceViews.TransactionsBatch />
                </Layout>
              )}
            />
            <Route
              exact
              path={`${APP_PATH.FINANCIAL_PERFORMANCE.BS_CATEGORY_TRANSACTIONS}/:categoryName/:categoryInternalAccountNumber`}
              render={({ match }) => (
                <Layout
                  headerTitle={startCase(match.params.categoryName)}
                  backHref={APP_PATH.FINANCIAL_PERFORMANCE.BALANCE_SHEET}
                >
                  <FinancialPerformanceYearsProvider>
                    <FinancialPerformanceViews.CategoryTransactions />
                  </FinancialPerformanceYearsProvider>
                </Layout>
              )}
            />
            <Route exact path={APP_PATH.FINANCIAL_PERFORMANCE.PROFIT_AND_LOSS}>
              <Layout headerTitle="Profit And Loss" showDataViewTypeButtons>
                {({ selectedDataViewType, setSelectedDataViewType }) => (
                  <FinancialPerformanceYearsProvider>
                    <FinancialPerformanceViews.ProfitAndLoss
                      {...{ selectedDataViewType, setSelectedDataViewType }}
                    />
                  </FinancialPerformanceYearsProvider>
                )}
              </Layout>
            </Route>
            <Route
              exact
              path={`${APP_PATH.FINANCIAL_PERFORMANCE.PL_TRANSACTIONS_BATCH}/:batchId`}
              render={({ match }) => (
                <Layout
                  htmlTitle={`${match.params.batchId} - Document Transactions`}
                  headerTitle="Document Transactions"
                  backHref={APP_PATH.FINANCIAL_PERFORMANCE.PROFIT_AND_LOSS}
                >
                  <FinancialPerformanceViews.TransactionsBatch />
                </Layout>
              )}
            />
            <Route
              exact
              path={`${APP_PATH.FINANCIAL_PERFORMANCE.PL_CATEGORY_TRANSACTIONS}/:categoryName/:categoryInternalAccountNumber`}
              render={({ match }) => (
                <Layout
                  headerTitle={startCase(match.params.categoryName)}
                  backHref={APP_PATH.FINANCIAL_PERFORMANCE.PROFIT_AND_LOSS}
                >
                  <FinancialPerformanceYearsProvider>
                    <FinancialPerformanceViews.CategoryTransactions />
                  </FinancialPerformanceYearsProvider>
                </Layout>
              )}
            />
            <Route exact path={APP_PATH.FINANCIAL_PERFORMANCE.PROFIT_PLAN}>
              <Layout headerTitle="Profit Plan">
                <FinancialPerformanceViews.ProfitPlan />
              </Layout>
            </Route>
            <Route exact path={APP_PATH.FINANCIAL_PERFORMANCE.ROOT}>
              <Redirect to={APP_PATH.FINANCIAL_PERFORMANCE.BALANCE_SHEET} />
            </Route>
            {/* Tax */}
            <Route exact path={APP_PATH.TAX.ESTIMATES}>
              <Layout
                htmlTitle="Quarterly Tax Estimates - Tax Dashboard"
                headerTitle="Tax Dashboard"
              >
                <TaxViews.QuarterlyTaxEstimates />
              </Layout>
            </Route>
            <Route exact path={APP_PATH.TAX.RETURNS}>
              <Layout
                htmlTitle="Tax Returns - Tax Dashboard"
                headerTitle="Tax Dashboard"
              >
                <TaxViews.TaxReturns />
              </Layout>
            </Route>
            <Route exact path={APP_PATH.TAX.ROOT}>
              <Redirect to="/tax/quarterly-estimates" />
            </Route>
            {/* Resources */}
            <Route exact path={APP_PATH.RESOURCES}>
              <Layout headerTitle="Resources">
                <ResourcesViews.Resources />
              </Layout>
            </Route>
            {/* HubMessages */}
            <Route exact path={APP_PATH.HUB_MESSAGES.ROOT}>
              <Layout headerTitle="Messages">
                <HubMessagesViews.AllHubMessages />
              </Layout>
            </Route>
            <Route
              exact
              path={`${APP_PATH.HUB_MESSAGES.VIEW}/:id`}
              render={({ match }) => (
                <Layout
                  headerTitle="Message Details"
                  htmlTitle={`${match.params.id} - Message`}
                >
                  <HubMessagesViews.HubMessageShow />
                </Layout>
              )}
            ></Route>
            {/* Tutorials */}
            {/* <Route exact path={APP_PATH.TUTORIALS}>
              <Layout headerTitle="Tutorials">
                <ComingSoon />
              </Layout>
            </Route> */}
            {/* Mileage Tracker */}
            <Route exact path={APP_PATH.MILEAGE_TRACKER.ROOT}>
              <Redirect to={APP_PATH.MILEAGE_TRACKER.TRIP_TRACKER} />
            </Route>
            <Route exact path={APP_PATH.MILEAGE_TRACKER.TRIP_TRACKER}>
              <Layout
                headerTitle="Trip Tracker"
                infoModalTriggerId={OPEN_MILEAGE_TRACKER_INFO_MODAL}
              >
                <MileageTrackerViews.TripTracker />
              </Layout>
            </Route>
            <Route
              exact
              path={APP_PATH.MILEAGE_TRACKER.LOCATION_PERMISSION_NEEDED}
            >
              <UploadFlowLayout htmlTitle="Location Permission Needed">
                <PermissionsSplash type={PERMISSIONS_SPLASH.location.type} />
              </UploadFlowLayout>
            </Route>
            <Route
              exact
              path={APP_PATH.MILEAGE_TRACKER.NOTIFICATION_PERMISSION_NEEDED}
            >
              <UploadFlowLayout htmlTitle="Notification Permission Needed">
                <PermissionsSplash
                  type={PERMISSIONS_SPLASH.notification.type}
                />
              </UploadFlowLayout>
            </Route>
            <Route exact path={APP_PATH.MILEAGE_TRACKER.ALL_TRIPS}>
              <Layout headerTitle="All Trips">
                <MileageTrackerViews.AllTrips />
              </Layout>
            </Route>
            <Route exact path={APP_PATH.MILEAGE_TRACKER.ADD_TRIP}>
              <Layout headerTitle="Add Manually">
                <MileageTrackerViews.AddTrip />
              </Layout>
            </Route>
            <Route
              exact
              path={`${APP_PATH.MILEAGE_TRACKER.EDIT_TRIP}/:tripId`}
              render={({ match }) => {
                const { tripId } = match.params
                // Prevent navigation to the edit page if it's a live trip
                const isLiveTrip =
                  toNumber(tripId) === getLocalStorageTrip()?.id
                if (isLiveTrip) {
                  return <Redirect to={APP_PATH.MILEAGE_TRACKER.TRIP_TRACKER} />
                }
                return (
                  <Layout
                    htmlTitle={`${match.params.tripId} - Manage Trip`}
                    headerTitle="Manage Trip"
                    backHref={APP_PATH.FINANCIAL_PERFORMANCE.BALANCE_SHEET}
                  >
                    <MileageTrackerViews.EditTrip />
                  </Layout>
                )
              }}
            />

            <Route exact path={APP_PATH.CONNECT_BANK}>
              <Layout headerTitle="Connect Bank">
                <ConnectBankViews.FlinksConnect />
              </Layout>
            </Route>

            {/* Home Redirect */}
            <Route exact path="/">
              <Redirect to={APP_PATH.HOME} />
            </Route>
            <Route>
              <Layout headerTitle="Page Not Found">
                <ErrorViews.PageNotFound />
              </Layout>
            </Route>
          </IonRouterOutlet>
        </NavMenuLayout>
      </AuthenticatedLayout>
    </IonRouterOutlet>
  )
}

export default React.memo(Routes)
