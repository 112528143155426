import React from 'react'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react'
import { APP_PATH } from 'config'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const propTypes = {
  addHeight: PropTypes.bool,
}

const defaultProps = {
  addHeight: false,
}
function HubMessagesNotificationCard({ addHeight }) {
  return (
    <IonCard
      className={classnames(
        'hub-messages-notification-card default-card-styles',
        { 'margin-top-20': addHeight }
      )}
      routerLink={`${APP_PATH.HUB_MESSAGES.ROOT}`}
    >
      <div>
        <IonCardHeader>
          <IonCardTitle>
            <span className="alert-dot"></span>New Message(s)
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent color="dark">
          You have one or more new messages
        </IonCardContent>
      </div>
      <IonButton fill="outline" color="dark">
        View
      </IonButton>
    </IonCard>
  )
}

HubMessagesNotificationCard.propTypes = propTypes
HubMessagesNotificationCard.defaultProps = defaultProps

export default React.memo(HubMessagesNotificationCard)
