import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import {
  RecentDocumentsCard,
  ConsultantContactCard,
  HubMessagesNotificationCard,
} from '../components'

const propTypes = {
  fetchLatestDocs: PropTypes.func.isRequired,
  fetchConsultantContact: PropTypes.func.isRequired,
  latestDocs: PropTypes.arrayOf(Types.doc),
  currentUser: Types.user.isRequired,
  hasActiveHubMessages: PropTypes.bool.isRequired,
}

const defaultProps = {
  latestDocs: null,
}

function LimitedUserHome({
  fetchLatestDocs,
  fetchConsultantContact,
  latestDocs,
  currentUser,
  hasActiveHubMessages,
}) {
  const { isActive } = currentUser
  return (
    <div className="home-dashboard-wrapper no-margin-top">
      {hasActiveHubMessages && <HubMessagesNotificationCard addHeight={true} />}
      <RecentDocumentsCard
        fetchLatestDocs={fetchLatestDocs}
        latestDocs={latestDocs}
      />
      {isActive && (
        <ConsultantContactCard
          fetchConsultantContact={fetchConsultantContact}
        />
      )}
    </div>
  )
}

LimitedUserHome.propTypes = propTypes
LimitedUserHome.defaultProps = defaultProps

export default React.memo(LimitedUserHome)
