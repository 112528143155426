import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  useIonViewWillEnter,
} from '@ionic/react'
import { CELL_FORMATTING, APP_PATH } from 'config'
import classNames from 'classnames'

const propTypes = {
  fetchHomeMileageSummary: PropTypes.func.isRequired,
  savings: PropTypes.number,
  setError: PropTypes.func.isRequired,
  addHeight: PropTypes.bool,
}

const defaultProps = {
  savings: null,
  addHeight: false,
}

function SavingsCard({
  savings,
  fetchHomeMileageSummary,
  setError,
  addHeight,
}) {
  useIonViewWillEnter(() => {
    const fetchSavings = async () => {
      try {
        await fetchHomeMileageSummary()
      } catch (errorMessage) {
        setError(
          `There was an issue loading your Mileage Savings data: ${errorMessage}`
        )
      }
    }

    fetchSavings()
  })

  return (
    <IonCard
      routerLink={APP_PATH.MILEAGE_TRACKER.ALL_TRIPS}
      button
      className={classNames({ 'margin-top-20': addHeight })}
    >
      <IonCardContent>
        <IonCardTitle>YTD Mileage Savings</IonCardTitle>
        <p>
          {savings?.toLocaleString('en-US', CELL_FORMATTING.CURRENCY) ?? '...'}
        </p>
      </IonCardContent>
    </IonCard>
  )
}

SavingsCard.propTypes = exact(propTypes)
SavingsCard.defaultProps = defaultProps

export default React.memo(SavingsCard)
